import { useState, useEffect } from "react";
import {
  Menu,
  X,
  Home,
  ListPlus,
  UserPlus,
  LogIn,
  ShoppingCart,
} from "lucide-react";
import logo from "../../assets/permanent/icon.webp";
import placeholder from "../../assets/permanent/Avatar.webp";
import SignInModal from "../SignInModal";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Avatar, Button, Dropdown, Modal } from "flowbite-react";
import { getAuth, signOut } from "firebase/auth";
import { signoutSuccess } from "../../redux/user/userSlice";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { toggleTheme } from "../../redux/theme/themeSlice";
import { FaMoon, FaSun } from "react-icons/fa";
import ReactGA from "react-ga4";

import { FaShareAlt } from "react-icons/fa";
export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("");
  const [open, setOpen] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [openSignOut, setOpenSignOut] = useState(false);
  const shareUrl = window.location.href;

  const [displayName, setDisplayName] = useState("");
  const { currentUser } = useSelector((state) => state.user);
  const path = useLocation().pathname;
  const dispatch = useDispatch();
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const { theme } = useSelector((state) => state.theme);
  const [imgSrc, setImgSrc] = useState(placeholder);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setisAdmin] = useState(false);
  useEffect(() => {
    if (photoUrl) {
      const img = new Image();
      img.src = photoUrl;
      img.onload = () => {
        setImgSrc(photoUrl); // Update to actual photoUrl when loaded
        setIsLoading(false);
      };
    } else {
      setImgSrc(placeholder);
      setIsLoading(false);
    }
  }, [photoUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser) {
          const ref = doc(db, "users", currentUser.uid);
          const snap = await getDoc(ref);
          setPhotoUrl(snap.data().photoUrl);
          setPhoneNumber(snap.data().phoneNumber);
          setDisplayName(snap.data().displayName);
          setisAdmin(snap.data().isAdmin);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [currentUser]);
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMenuOpen]);
  const handleDialog = () => {
    setOpenModal(true);
  };
  const handleSignout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setOpenSignOut(false);
      dispatch(signoutSuccess());
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleTracking = (platform) => {
    ReactGA.event({
      action: "share",
      category: "sikkim.co",
      label: `User is sharing sikkim.co to ${platform}`,
    });
  };
  return (
    <header className="bg-black text-white shadow-md hover:shadow-yellow-100 dark:hover:shadow-yellow-100 dark:shadow-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Mobile Layout */}
          <div className="flex items-center sm:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              <Menu className="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="flex items-center justify-center sm:hidden">
            {" "}
            <Button
              className="w-12 h-10 sm:inline mr-2"
              color="gray"
              pill
              onClick={() => dispatch(toggleTheme())}
              aria-label="Theme Change"
            >
              {theme === "light" ? <FaSun /> : <FaMoon />}
            </Button>
            {currentUser ? (
              <Dropdown
                inline
                label={
                  isLoading ? (
                    <Avatar alt="user" rounded img={placeholder} />
                  ) : (
                    <Avatar alt="user" rounded img={imgSrc} /> ||
                    (displayName && `Hi, ${displayName}`) ||
                    phoneNumber
                  )
                }
                arrowIcon={false}
              >
                {" "}
                {isAdmin && (
                  <Link to={"/admin-skm"}>
                    <Dropdown.Item>Admin Dashboard</Dropdown.Item>
                  </Link>
                )}
                <Link to={"/dashboard?tab=profile"}>
                  <Dropdown.Item>Profile</Dropdown.Item>
                </Link>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setOpenSignOut(true)}>
                  Sign Out
                </Dropdown.Item>
              </Dropdown>
            ) : (
              <button
                onClick={handleDialog}
                className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-black bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              >
                <LogIn className="w-4 h-4 mr-1" />
                Sign In
              </button>
            )}
          </div>
          {/* Desktop Layout */}
          <div className="hidden sm:flex sm:items-center">
            <a href="/" className="flex-shrink-0 mr-4">
              <img src={logo} alt="Waebsite Logo" width={32} height={32} />
            </a>
          </div>
          <div className="hidden sm:flex sm:items-center sm:justify-between sm:flex-1">
            <nav className="flex space-x-8 ml-10">
              <Nava
                href="/"
                icon={
                  <Home
                    className={`w-4 h-4 mr-1 ${path === "/" ? "text-yellow-200" : null}`}
                  />
                }
              >
                <p className={`${path === "/" ? "text-yellow-200" : null}`}>
                  Home
                </p>
              </Nava>
              <Nava
                href="/get-listed"
                icon={
                  <ListPlus
                    className={`w-4 h-4 mr-1 ${path === "/get-listed" ? "text-yellow-200" : null}`}
                  />
                }
              >
                <p
                  className={`${path === "/get-listed" ? "text-yellow-200" : null}`}
                >
                  Get Listed
                </p>
              </Nava>
              <Nava
                href="/market"
                icon={
                  <ShoppingCart
                    className={`w-4 h-4 mr-1 ${path === "/market" ? "text-yellow-200" : null}`}
                  />
                }
              >
                <p
                  className={`${path === "/market" ? "text-yellow-200" : null}`}
                >
                  Market Place
                </p>
              </Nava>
            </nav>
            <div className="flex items-center">
              <Button
                aria-label="Theme Change"
                className="w-12 h-10 sm:inline mr-2"
                color="gray"
                pill
                onClick={() => dispatch(toggleTheme())}
              >
                {theme === "light" ? <FaSun /> : <FaMoon />}
              </Button>
              {currentUser ? (
                <Dropdown
                  inline
                  label={
                    isLoading ? (
                      <Avatar alt="user" rounded img={placeholder} />
                    ) : (
                      <Avatar alt="user" rounded img={imgSrc} /> ||
                      (displayName && `Hi, ${displayName}`) ||
                      phoneNumber
                    )
                  }
                  arrowIcon={false}
                >
                  {isAdmin && (
                    <Link to={"/admin-skm"}>
                      <Dropdown.Item>Admin Dashboard</Dropdown.Item>
                    </Link>
                  )}
                  <Link to={"/dashboard?tab=profile"}>
                    <Dropdown.Item>Profile</Dropdown.Item>
                  </Link>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => setOpenSignOut(true)}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown>
              ) : (
                <>
                  <button
                    onClick={handleDialog}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                  >
                    <UserPlus className="w-4 h-4 mr-1" />
                    Sign In
                  </button>
                  <SignInModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={openSignOut} onClose={() => setOpenSignOut(false)}>
        <Modal.Header>Are you sure you want to sign out?</Modal.Header>
        <Modal.Body className="flex justify-center">
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-white hover:text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            onClick={handleSignout}
          >
            Confirm
          </button>
        </Modal.Body>
      </Modal>

      {/* Drawer for mobile */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out ${
          isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={toggleMenu}
      >
        <div
          className={`fixed inset-y-0 left-0 w-4/5 max-w-sm bg-black shadow-xl transform transition-transform duration-300 ease-in-out ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-4 border-b border-gray-700">
            <a href="/" className="flex-shrink-0">
              <img src={logo} alt="Website Logo" width={32} height={32} />
            </a>
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Close main menu</span>
              <X className="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <MobileNava
              href="/"
              icon={
                <Home
                  className={`w-4 h-4 mr-1 ${path === "/" ? "text-yellow-200" : null}`}
                />
              }
            >
              <p className={`${path === "/" ? "text-yellow-200" : null}`}>
                Home
              </p>
            </MobileNava>
            <MobileNava
              href="/get-listed"
              icon={
                <ListPlus
                  className={`w-4 h-4 mr-1 ${path === "/get-listed" ? "text-yellow-200" : null}`}
                />
              }
            >
              <p
                className={`${path === "/get-listed" ? "text-yellow-200" : null}`}
              >
                Get Listed
              </p>
            </MobileNava>
            <MobileNava
              href="/market"
              icon={
                <ShoppingCart
                  className={`w-4 h-4 mr-1 ${path === "/market" ? "text-yellow-200" : null}`}
                />
              }
            >
              <p className={`${path === "/market" ? "text-yellow-200" : null}`}>
                Market Place{" "}
              </p>
            </MobileNava>{" "}
            <MobileNava
              href="#"
              onClick={() => setOpen(true)}
              icon={
                <FaShareAlt
                  className={`w-4 h-4 mr-1`}
                  onClick={() => setOpen(true)}
                />
              }
            >
              <div onClick={() => setOpen(true)}>Share</div>
            </MobileNava>
          </div>
          <Modal
            show={open}
            onClose={() => setOpen(false)}
            className={`flex items-center justify-center ${theme === "dark" ? "dark" : ""}`}
          >
            <Modal.Header onClose={() => setOpen(false)}>
              Share us on your socials
            </Modal.Header>
            <Modal.Body className="flex gap-5 justify-center">
              <FacebookShareButton
                url={shareUrl}
                quote="Visit Now"
                onClick={() => handleTracking("Facebook")}
              >
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>
              <WhatsappShareButton
                url={shareUrl}
                title="Visit Now"
                onClick={() => handleTracking("Whatsapp")}
              >
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
              <TwitterShareButton
                url={shareUrl}
                title="Visit Now"
                onClick={() => handleTracking("Twitter")}
              >
                <TwitterIcon size={40} round={true} />
              </TwitterShareButton>
              <TelegramShareButton
                url={shareUrl}
                title="Visit Now"
                onClick={() => handleTracking("Telegram")}
              >
                <TelegramIcon size={40} round={true} />
              </TelegramShareButton>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </header>
  );
}

function Nava({ href, children, icon }) {
  return (
    <a
      href={href}
      className="inline-flex items-center px-1 pt-1 text-sm font-medium text-white hover:text-gray-300"
    >
      {icon}
      {children}
    </a>
  );
}

function MobileNava({ href, children, icon }) {
  return (
    <a
      href={href}
      className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-300 hover:bg-gray-800"
    >
      <div className="flex items-center">
        {icon}
        {children}
      </div>
    </a>
  );
}
