import { Button, Modal } from "flowbite-react";
import React, { useState } from "react";
import OAuth from "./OAuth";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PhoneAuth from "./PhoneAuth";
import { IoIosArrowBack } from "react-icons/io";

function SignInModal({ setOpenModal, openModal }) {
  const { theme } = useSelector((state) => state.theme);
  const [phone, setPhone] = useState(false);
  return (
    <div>
      {!phone && (
        <Modal
          dismissible
          size="md"
          className={`flex items-center justify-center ${theme === "dark" ? "dark" : ""}`}
          show={openModal}
          onClose={() => setOpenModal(false)}
        >
          <Modal.Body className="flex flex-col gap-2 items-center justify-center  p-6">
            <OAuth />
            <Button
              gradientDuoTone="purpleToBlue"
              outline
              onClick={() => {
                setPhone(true);
                setOpenModal(false);
              }}
            >
              Sign in with Phone Number
            </Button>
          </Modal.Body>
          <p
            onClick={() => setOpenModal(false)}
            className="text-red-600 text-center mb-2 -mt-3 mx-auto wrap font-semibold py-2 px-4 border border-red-600 hover:bg-red-300 cursor-pointer rounded-3xl "
          >
            Close
          </p>
        </Modal>
      )}
      {phone && (
        <Modal
          dismissible
          size="md"
          className={`flex overflow-none items-center justify-center ${theme === "dark" ? "dark" : ""}`}
          show={phone}
          onClose={() => setPhone(false)}
        >
          <Modal.Body className="flex flex-col gap-2 items-center justify-center  p-6">
            <div
              onClick={() => {
                setOpenModal(true);
                setPhone(false);
              }}
              className="flex items-center w-full cursor-pointer hover:text-blue-200"
            >
              <IoIosArrowBack className=" mt-0.5" />
              <p>Back</p>
            </div>
            <PhoneAuth />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default SignInModal;
