import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useSelector } from "react-redux";

const ProtectedRouteForSpecial = () => {
  const location = useLocation();
  const { currentUser } = useSelector((state) => state.user);
  const [allowed, setAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const userCheck = async () => {
      if (currentUser) {
        try {
          const ref = doc(db, "specialUsers", currentUser.uid);
          const snap = await getDoc(ref);
          if (snap.exists()) {
            setAllowed(true);
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      }
      setLoading(false);
    };
    userCheck();
  }, [currentUser]);

  if (!currentUser) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  // Check if the user has access to the requested part
  if (!allowed) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRouteForSpecial;
