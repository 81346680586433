import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";

// Function to calculate age
const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export default function QRTarget() {
  const { detailId } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const ref = doc(db, "temp", detailId);
      const docSnap = await getDoc(ref);
      setData(docSnap.data());
    };
    fetchData();
  }, []);
  const age = calculateAge(data.dob);
  return (
    <div className="container mx-auto p-4 min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-4 sm:p-6">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
            {data.name}
          </h2>
          <div className="flex flex-col md:flex-row items-center gap-6">
            <div className="w-48 h-48 relative rounded-full overflow-hidden border-4 border-gray-200">
              <img
                src={data.photoUrl}
                alt={data.name}
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className="flex-1 space-y-4">
              <p className="text-lg text-gray-700">
                <span className="font-semibold text-gray-900">Age:</span> {age}{" "}
                years
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold text-gray-900">
                  Date of Birth:
                </span>{" "}
                {new Date(data.dob).toLocaleDateString()}
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold text-gray-900">Post:</span>{" "}
                {data.post}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
