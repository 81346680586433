import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import img from "../../assets/permanent/Banner.webp";
export default function MarketPlaceTopSection() {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const containerVariants = {
    small: { height: "100vh" },
    large: { height: "auto" },
  };

  return (
    <motion.section
      className="relative flex items-center justify-center overflow-hidden"
      initial={windowWidth < 768 ? "small" : "large"}
      animate={windowWidth < 768 ? "small" : "large"}
      variants={containerVariants}
      transition={{ duration: 0.5 }}
    >
      <div className="absolute inset-0">
        <img
          src={`${img}?height=1080&width=1920`}
          alt="Hero background"
          layout="fill"
          objectfit="cover"
          quality={100}
          className="h-full object-cover md:w-full"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50" />
      </div>
      <div className="relative z-10 text-center px-4 sm:px-6 lg:px-8 py-16 md:py-24 lg:py-32">
        <motion.h1
          className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4"
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          Discover the Essence of Sikkim: Authentic Local Products{" "}
        </motion.h1>
        <motion.p
          className="text-xl sm:text-2xl md:text-3xl text-white max-w-3xl mx-auto"
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          From Organic Teas to Handwoven Textiles—Experience the Heart of Sikkim
        </motion.p>
      </div>
    </motion.section>
  );
}
