import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: "sikkim-co.firebaseapp.com",
  projectId: "sikkim-co",
  storageBucket: "sikkim-co.appspot.com",
  messagingSenderId: "363972869305",
  appId: "1:363972869305:web:808a03734fe31c66856f68",
  measurementId: "G-842Q17ZYK5",
};

/*const firebaseConfig = {
  apiKey: "AIzaSyAXzVqwHEFMq0mfPIXHr9j3hHzQnJIDgCQ",
  authDomain: "test-4df6c.firebaseapp.com",
  projectId: "test-4df6c",
  storageBucket: "test-4df6c.appspot.com",
  messagingSenderId: "598210394265",
  appId: "1:598210394265:web:5fd616d58993e518bb2008",
};*/

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); // Initialize Firebase Authentication
export const storage = getStorage(app);
