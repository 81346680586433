import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { Modal, ModalBody, ModalHeader, Select, Table } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebase.js";
import Logo3 from "./imagess.png";
import sign from "./63f625050c1df924c4aac61c_mj-min.png";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import exec from "./exec.png";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../../../firebase.js";
import water from "../../../assets/favicon.png";
import orange from "../../../assets/orange.png";
import red from "../../../assets/red.png";
import green from "../../../assets/green.png";
const paid = [
  {
    paid: "Paid",
  },
  {
    paid: "Unpaid",
  },
  {
    paid: "Unpaid",
  },
  {
    paid: "Unpaid",
  },
  {
    paid: "Unpaid",
  },
];

function CECs() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [idInfo, setIdInfo] = useState([]);
  const [generateOpen, setGenerateOpen] = useState(false);
  const [sendingStatus, setSendingStatus] = useState({});
  const [sendingBulk, setSendingBulk] = useState(false);
  const [level, setLevel] = useState("CEC");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [front, setFront] = useState(true);
  const [view, setView] = useState(false);
  const [savedIds, setSavedIds] = useState([]);
  const [docuId, setDocuId] = useState("");

  const link = window.location.href;
  useEffect(() => {
    const fetchData = async () => {
      const data = collection(db, "temp");
      // let q = query(data);
      const snapshot = await getDocs(data);
      let userList = snapshot.docs.map((doc) => ({
        id: doc.id, // Store the docID along with other user data
        ...doc.data(),
      }));
      setUsers(userList);
    };

    fetchData();
  }, []);
  const printRef = useRef();

  const handleRowClick = (rowId) => {
    // Check if the row is already selected
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      // Row is not selected, add it to the selectedRows array
      newSelectedRows = [...selectedRows, rowId];
    } else {
      // Row is already selected, remove it from the selectedRows array
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }
    // Update the selectedRows state
    setSelectedRows(newSelectedRows);
  };
  const handleGenerate = async (e) => {
    const data = doc(db, "temp", e);
    const snapShot = await getDoc(data);
    setGenerateOpen(true);
    setIdInfo(snapShot.data());
    setDocuId(e);
  };

  // const handlePrint = () => {
  //   const printContents = printRef.current.innerHTML;
  //   const originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  //   window.location.reload();
  // };
  const handleDownload = async () => {
    setLoading(true);
    try {
      const element = printRef.current;

      // Ensure the image is loaded before capturing
      const images = element.querySelectorAll("img");
      await Promise.all(
        Array.from(images).map((img) => {
          return new Promise((resolve) => {
            if (img.complete) {
              resolve();
            } else {
              img.onload = resolve;
              img.onerror = resolve;
            }
          });
        })
      );

      const canvas = await html2canvas(element, {
        useCORS: true, // Enable CORS if possible
        scale: 3, // Increase scale to 3x the default resolution for better quality
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0); // Use maximum quality (1.0)

      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${idInfo.name}_ID.jpg`;
      link.click();
      setLoading(false);
    } catch (error) {
      console.error("Error capturing or downloading image: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (printRef.current) {
      const dimensions = printRef.current.getBoundingClientRect();
      console.log("Dimensions:", dimensions);
    }
  }, [generateOpen]); // Runs when the modal is opened
  const handleSendReminder = async (userId) => {
    setSendingStatus((prevStatus) => ({ ...prevStatus, [userId]: true }));
    try {
      const userDoc = doc(db, "temp", userId);
      const userSnap = await getDoc(userDoc);
      const userData = userSnap.data();

      const response = await fetch(
        "https://us-central1-sikkim-co.cloudfunctions.net/sendReminderEmails",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            to: userData.email,
            subject: "SKM-CEC Membership Fee",
            html: `
            <p>Dear ${userData.name},</p>
            <p>We noticed that your payment is still pending. Please complete your payment at your earliest convenience.</p>
            <p>If you have any questions or need assistance, feel free to reach out to us.</p>
            <p>Thank you for your attention to this matter.</p>
            <p>Best regards,</p>
            <p>Admin</p>
            <p>SKM HQ, Ghaley Compound</p>
          `,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send reminder");
      }

      alert("Reminder sent successfully");
    } catch (error) {
      console.error("Error sending reminder:", error);
      alert("Error sending reminder");
    } finally {
      setSendingStatus((prevStatus) => ({ ...prevStatus, [userId]: false }));
    }
  };
  const handleSendBulkReminder = async () => {
    setSendingBulk(true);
    try {
      const unpaidUsers = users.filter((user) => !user.payment && user.email);

      if (unpaidUsers.length === 0) {
        alert("No unpaid users with email addresses.");
        return;
      }

      const emailPromises = unpaidUsers.map(async (user) => {
        try {
          const response = await fetch(
            "https://us-central1-sikkim-co.cloudfunctions.net/sendReminderEmails",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                to: user.email,
                subject: "SKM-CEC Membership Fee",
                html: `
                            <p>Dear ${user.name},</p>
                            <p>We noticed that your payment is still pending. Please complete your payment at your earliest convenience.</p>
                            <p>If you have any questions or need assistance, feel free to reach out to us.</p>
                            <p>Thank you for your attention to this matter.</p>
                            <p>Best regards,</p>
                            <p>Admin</p>
                            <p>SKM HQ, Ghaley Compound</p>
                                      
                          `,
              }),
            }
          );

          if (!response.ok) {
            throw new Error(`Failed to send email to ${user.email}`);
          }
        } catch (error) {
          console.error(`Error sending email to ${user.email}:`, error);
        }
      });

      await Promise.all(emailPromises);
      alert("Reminders sent successfully");
    } catch (error) {
      console.error("Error sending bulk reminders:", error);
      alert("Error sending reminders");
    } finally {
      setSendingBulk(false);
    }
  };
  const handleSave = async () => {
    setLoading1(true);
    try {
      const element = printRef.current;

      const images = element.querySelectorAll("img");
      await Promise.all(
        Array.from(images).map((img) => {
          return new Promise((resolve) => {
            if (img.complete) {
              resolve();
            } else {
              img.onload = resolve;
              img.onerror = resolve;
            }
          });
        })
      );

      const canvas = await html2canvas(element, {
        useCORS: true,
        scale: 3, // Increase scale for better quality
      });

      const blob = await new Promise(
        (resolve) => canvas.toBlob(resolve, "image/jpeg", 1.0) // Set max quality to 1.0
      );

      if (!blob) {
        throw new Error("Failed to create blob from canvas");
      }

      const storageRef = ref(storage, `ids/${idInfo.name}_ID.jpg`);

      const snapshot = await uploadBytes(storageRef, blob);

      const downloadURL = await getDownloadURL(snapshot.ref);

      alert("File saved successfully!");
      setLoading1(false);
      console.log("File available at", downloadURL);
    } catch (error) {
      console.error("Error saving image to Firebase Storage: ", error);
      alert("Error saving image.");
      setLoading1(false);
    }
  };
  const getFilesFromDirectory = async () => {
    try {
      const directoryRef = ref(storage, "ids/");

      const res = await listAll(directoryRef);

      const urls = await Promise.all(
        res.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return {
            name: itemRef.name,
            url: url,
          };
        })
      );
      setSavedIds(urls);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const renderRows = () => {
    return users.map((user) => (
      <Table.Row
        key={user.id}
        className="border"
        onClick={() => handleRowClick(user.id)}
        // style={{
        //   backgroundColor: selectedRows.includes(user.id)
        //     ? "lightblue"
        //     : "white",
        // }}
      >
        <Table.Cell align="center">{user.name}</Table.Cell>
        <Table.Cell align="center">{user.post}</Table.Cell>
        <Table.Cell align="center">{user.wing}</Table.Cell>
        {user.payment ? (
          <Table.Cell align="center" className="flex flex-col gap-2">
            Paid
            <Link
              className="bg-black text-white py-1 rounded-md hover:bg-slate-600"
              to={`/cec/${user.id}`}
            >
              Generate Ack Slip
            </Link>
          </Table.Cell>
        ) : (
          <Table.Cell align="center" className="flex flex-col gap-2">
            Unpaid
            <button
              onClick={() => handleSendReminder(user.id)}
              className="bg-black hover:bg-slate-600 text-white py-1 rounded-md"
            >
              {" "}
              {sendingStatus[user.id] ? "Sending..." : "Send Reminder"}
            </button>
          </Table.Cell>
        )}

        {/* <Table.Cell align="center">{user.wing}</Table.Cell> */}
        <Table.Cell align="center">
          <button
            className="bg-black text-white px-4 py-2 rounded-md hover:bg-slate-600"
            onClick={() => handleGenerate(user.id)}
          >
            Generate
          </button>
        </Table.Cell>
      </Table.Row>
    ));
  };
  return (
    <div className="min-h-screen">
      <div className="overflow-x-auto">
        <h1 className="text-center text-3xl italic p-2 underline">
          SKM MEMBERS
        </h1>
        <div className="flex flex-col">
          <button
            onClick={() => {
              getFilesFromDirectory();
              setView(!view);
            }}
            className={`border m-2 bg-white  border-black text-black px-4 py-2 rounded-md hover:bg-gray-100 active:bg-gray-200`}
          >
            View Saved ID Cards
          </button>
          {view ? (
            <>
              <div className="flex m-2 flex-wrap gap-5 items-center justify-center">
                {savedIds.map((item, index) => (
                  <img
                    src={item.url}
                    key={index}
                    alt={item.name}
                    className="sm:w-[450px] sm:h-[280px] w-[350px] h-[180px] object-cover"
                  />
                ))}
              </div>
            </>
          ) : null}
        </div>
        <Table>
          <Table.Head className="border-2">
            <Table.HeadCell align="center">Name </Table.HeadCell>
            <Table.HeadCell align="center">Post</Table.HeadCell>
            <Table.HeadCell align="center">Wing</Table.HeadCell>
            <Table.HeadCell align="center" className="flex flex-col">
              MemberShip{" "}
              <button
                onClick={handleSendBulkReminder}
                className="bg-black text-white px-4 py-2 rounded-md hover:bg-slate-600"
                disabled={sendingBulk}
              >
                {sendingBulk ? "Sending..." : "Send Reminders to all"}
              </button>
            </Table.HeadCell>
            <Table.HeadCell align="center">Generate ID</Table.HeadCell>
          </Table.Head>
          <Table.Body>{renderRows()}</Table.Body>
        </Table>
      </div>

      <Modal
        show={generateOpen}
        onClose={() => setGenerateOpen(false)}
        // size="lg"
        className="w-auto max-w-fit bg-transparent"
        dismissible
      >
        <ModalHeader onClose={() => setGenerateOpen(false)}>
          <p>ID Card of {idInfo.name}</p>
          <Select onChange={(e) => setLevel(e.target.value)}>
            <option value="CEC">CEC</option>
            <option value="DEC">DEC</option>
            <option value="CLC">CLC</option>
            <option value="WLC">WLC</option>
          </Select>
        </ModalHeader>
        <ModalBody>
          <div
            className={`w-[450px] h-[280px] ${front ? "bg-gradient-to-b from-orange-300 via-red-300 to-green-300" : "bg-gradient-to-b from-green-300 via-red-300 to-orange-300"} ${level === "DEC" && "bg-gradient-to-br from-gray-500 via-gray-400 to-gray-500"} ${level === "CLC" && "bg-gradient-to-br from-green-400 via-green-300 to-green-400"} ${level === "WLC" && "bg-gradient-to-br from-blue-500 via-blue-300 to-blue-500"} text-white overflow-hidden relative rounded-lg`}
            ref={printRef}
          >
            {front ? (
              <div className="relative z-10 px-6 py-1 h-full flex flex-col justify-between">
                <div className="flex flex-col items-center justify-between">
                  <div className="flex self-start items-center space-x-4">
                    <div
                      className={`w-14 h-14 rounded-full  bg-gradient-to-r from-amber-400 to-amber-600 flex items-center justify-center`}
                    >
                      <img src={Logo3} alt="Logo" className="w-12 h-12" />
                    </div>
                    <div className="-m5">
                      <img
                        src={orange}
                        className="absolute left-[85px] -z-10 w-80 h-8"
                      />
                      <h2 className="font-bold text-lg text-black tracking-wide -mt-2">
                        SIKKIM KRANTIKARI MORCHA
                      </h2>
                      <img
                        src={red}
                        className="absolute left-[85px] -z-10 w-80 h-8"
                      />
                      <p className={`text-xs text-black`}>
                        Ghaley Compound, Tibet Road, Gangtok{" "}
                      </p>

                      <img
                        src={green}
                        className="absolute left-[85px] top-[46px] -z-10 w-80 h-8"
                      />
                      <p className="text-center font-bold text-lg">EXECUTIVE</p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center space-x-6">
                  <img
                    src={idInfo.photoUrl}
                    alt={idInfo.name}
                    className="w-28 h-28 rounded-lg object-cover border-2 border-amber-400 shadow-lg"
                  />
                  <div>
                    <h3 className="font-bold text-2xl text-black">
                      {idInfo.name}
                    </h3>
                    <h3 className="font-bold text-sm text-black">dd/mm/yyyy</h3>
                    <p className="text-sm font-medium text-black mt-1">
                      {idInfo.post}
                    </p>
                    <p className={`text-sm text-black`}>{idInfo.wing}</p>
                    {/* <p className="text-xs text-slate-500 mt-2">ID: {idInfo.id}</p> */}
                  </div>
                </div>

                <div className="flex justify-between items-end mt-6">
                  <div>
                    <img
                      src={sign}
                      alt="Signature"
                      className="w-28 rounded-md mb-1  bg-white absolute"
                      style={{ bottom: "5px" }}
                    />
                  </div>
                  <div
                    className="absolute"
                    style={{ bottom: "50px", right: "30px", opacity: "40%" }}
                  >
                    <img src={water} />
                  </div>
                  {/* <div
                  className="absolute"
                  style={{ bottom: "30px", right: "20px" }}
                >
                  <QRCode
                    value={`https://sikkim.co/cec/${docuId}`}
                    size={74}
                    bgColor="black"
                    fgColor="#fff"
                  />
                </div> */}
                </div>
              </div>
            ) : (
              <div className="relative z-10 px-6 py-1 h-full flex flex-col justify-between">
                <div className="absolute bottom-[60px] left-[140px] ">
                  <img src={water} className="w-36 h-36 opacity-40 -z-10" />
                </div>
                <div className="flex flex-col items-center justify-between z-10">
                  <div className="flex self-start items-center space-x-4">
                    <div
                      className={`w-14 h-14 rounded-full  bg-gradient-to-r from-amber-400 to-amber-600 flex items-center justify-center`}
                    >
                      <img src={Logo3} alt="Logo" className="w-12 h-12" />
                    </div>
                    <div className="-m5">
                      <img
                        src={orange}
                        className="absolute left-[85px] -z-10 w-80 h-8"
                      />
                      <h2 className="font-bold text-lg text-black tracking-wide -mt-2">
                        SIKKIM KRANTIKARI MORCHA
                      </h2>
                      <img
                        src={red}
                        className="absolute left-[85px] -z-10 w-80 h-8"
                      />
                      <p className={`text-xs text-black`}>
                        Ghaley Compound, Tibet Road, Gangtok{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center space-x-6">
                  <div>
                    <h3 className="font-bold text-2xl text-black">
                      {idInfo.name}
                    </h3>
                    <h3 className="font-bold text-sm text-black">dd/mm/yyyy</h3>
                    <p className="text-sm font-medium text-black mt-1">
                      {idInfo.post}
                    </p>
                    <p className={`text-sm text-black`}>{idInfo.wing}</p>
                    <p className={`text-sm text-black`}>Address Line 1</p>
                    <p className={`text-sm text-black`}>
                      Address Line 2 - 785410
                    </p>
                    {/* <p className="text-xs text-slate-500 mt-2">ID: {idInfo.id}</p> */}
                  </div>
                </div>

                <div className="flex justify-between items-end mt-6">
                  {/* <div>
                    <img
                      src={sign}
                      alt="Signature"
                      className="w-28 rounded-md mb-1  bg-white absolute"
                      style={{ bottom: "5px" }}
                    />
                  </div> */}

                  <div
                    className="absolute"
                    style={{ bottom: "50px", right: "40px" }}
                  >
                    <QRCode
                      value={`https://sikkim.co/cec/${docuId}`}
                      size={120}
                      bgColor="black"
                      fgColor="#fff"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <div className="flex justify-evenly mb-2">
          <button
            className={`${loading && "cursor-not-allowed"} bg-black text-white px-4 py-2 rounded-md`}
            onClick={handleDownload}
            disabled={loading}
          >
            {loading ? "Downloading..." : "Download"}
          </button>{" "}
          <button
            className={`${loading && "cursor-not-allowed"} bg-black text-white px-4 py-2 rounded-md`}
            onClick={() => setFront(!front)}
            disabled={loading}
          >
            {front ? "Front" : "Back"}
          </button>
          <button
            className={`${loading1 && "cursor-not-allowed"} bg-black text-white px-4 py-2 rounded-md`}
            onClick={handleSave}
            disabled={loading1}
          >
            {loading1 ? "Saving..." : "Save in Cloud"}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default CECs;
