import { Spinner } from "flowbite-react";
import React from "react";

function Loading() {
  return (
    <div className="w-full gap-2 min-h-screen h-full flex flex-col items-center justify-center">
      <Spinner
        color="purple"
        className="w-56 h-56 border border-black bg-black rounded-full"
      />
      <h1 className="text-lg font-semibold italic">
        Your Page is Loading! Please wait...
      </h1>
    </div>
  );
}

export default Loading;
