import { useEffect, useState } from "react";
import { Star, Truck, RotateCcw, ShieldCheck } from "lucide-react";
import { useParams } from "react-router-dom";
import turmeric from "../../assets/marketPlace/turmeric/NEOriginsLakadongTurmericPR00-1.webp";
import turmeric1 from "../../assets/marketPlace/turmeric/NEOriginsLakadongTurmericPR03.webp";
import turmeric2 from "../../assets/marketPlace/turmeric/NEOriginsLakadongTurmericPR01.webp";
import turmeric3 from "../../assets/marketPlace/turmeric/NEOriginsLakadongTurmericPR02.webp";
import tea from "../../assets/marketPlace/tea/new-temi-tea-first-flush.webp";
import tea1 from "../../assets/marketPlace/tea/temi-tea-first-flush2.webp";
import tea2 from "../../assets/marketPlace/tea/temi-tea-first-flush3.webp";
import MarketplaceSubHeader from "../../components/marketPlace/MarketplaceSubHeader";
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../../redux/shopping/checkOutSlice.js";

const products = [
  {
    id: 1,
    name: "Lakadong Turmeric",
    price: 229.99,
    rating: 4.5,
    reviews: 1,
    description:
      "Our Lakadong Turmeric is a farmer-direct product that originates from the secluded village of Lakadong in Meghalaya. Lakadong turmeric has a high curcumin content of 12%. With various health benefits, this turmeric is a pantry must-have. A pinch of our haldi is enough to enhance the colour, flavour, and aroma of your recipe.",
    colors: ["White", "Black", "Navy", "Red"],
    sizes: ["50g", "100g", "300g", "500g", "1kg"],
    images: [turmeric, turmeric1, turmeric2, turmeric3],
    features: ["100% organic"],
  },
  {
    id: 2,
    name: "Temi Tea First Flush, 100g",
    price: 500,
    rating: 4.2,
    reviews: 95,
    description:
      "The first or the spring flush is the rarest of the rare, this is the first harvest after the cold winter in Sikkim. The first flush is considered the best because of the pause it gets after the autumn harvest throughout the winter.",
    colors: ["Blue", "Black", "Grey"],
    sizes: ["100g", "300g", "500g", "1kg"],
    images: [tea, tea1, tea2],
    features: [],
  },
  {
    id: 3,
    name: "Lakadong Turmeric",
    price: 229.99,
    rating: 4.5,
    reviews: 1,
    description:
      "Our Lakadong Turmeric is a farmer-direct product that originates from the secluded village of Lakadong in Meghalaya. Lakadong turmeric has a high curcumin content of 12%. With various health benefits, this turmeric is a pantry must-have. A pinch of our haldi is enough to enhance the colour, flavour, and aroma of your recipe.",
    colors: ["White", "Black", "Navy", "Red"],
    sizes: ["50g", "100g", "300g", "500g", "1kg"],
    images: [turmeric, turmeric1, turmeric2, turmeric3],
    features: ["100% organic"],
  },
  {
    id: 4,
    name: "Temi Tea First Flush, 100g",
    price: 500,
    rating: 4.2,
    reviews: 95,
    description:
      "The first or the spring flush is the rarest of the rare, this is the first harvest after the cold winter in Sikkim. The first flush is considered the best because of the pause it gets after the autumn harvest throughout the winter.",
    colors: ["Blue", "Black", "Grey"],
    sizes: ["100g", "300g", "500g", "1kg"],
    images: [tea, tea1, tea2],
    features: [],
  },
  {
    id: 5,
    name: "Lakadong Turmeric",
    price: 229.99,
    rating: 4.5,
    reviews: 1,
    description:
      "Our Lakadong Turmeric is a farmer-direct product that originates from the secluded village of Lakadong in Meghalaya. Lakadong turmeric has a high curcumin content of 12%. With various health benefits, this turmeric is a pantry must-have. A pinch of our haldi is enough to enhance the colour, flavour, and aroma of your recipe.",
    colors: ["White", "Black", "Navy", "Red"],
    sizes: ["50g", "100g", "300g", "500g", "1kg"],
    images: [turmeric, turmeric1, turmeric2, turmeric3],
    features: ["100% organic"],
  },
  {
    id: 6,
    name: "Temi Tea First Flush, 100g",
    price: 500,
    rating: 4.2,
    reviews: 95,
    description:
      "The first or the spring flush is the rarest of the rare, this is the first harvest after the cold winter in Sikkim. The first flush is considered the best because of the pause it gets after the autumn harvest throughout the winter.",
    colors: ["Blue", "Black", "Grey"],
    sizes: ["100g", "300g", "500g", "1kg"],
    images: [tea, tea1, tea2],
    features: [],
  },
];

export default function ProductsPage() {
  const { productId } = useParams();
  const product = products.find((p) => p.id === parseInt(productId));
  const dispatch = useDispatch();
  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [selectedSize, setSelectedSize] = useState(product.sizes[0]);
  const [quantity, setQuantity] = useState(1);
  const [mainImage, setMainImage] = useState(product.images[0]);
  const [addedToCart, setAddedToCart] = useState({});
  const [added, setAdded] = useState(false);
  const cartItems = useSelector((state) => state.cart.items);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (!product) {
    return <div>Product not found!</div>;
  }
  const handleAddToCart = (e, product) => {
    e.preventDefault();
    dispatch(addItem(product));
    setAddedToCart((prevState) => ({
      ...prevState,
      [product.name]: true, // Set the product name as key and value as true to indicate it's added to cart
    }));
    setAdded(true);
  };

  return (
    <>
      <MarketplaceSubHeader />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
          {/* Left column - Image gallery */}
          <div className="flex flex-col-reverse">
            {/* Image selector */}
            <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
              <div
                className="grid grid-cols-4 gap-6"
                aria-orientation="horizontal"
                role="tablist"
              >
                {product.images.map((image, index) => (
                  <button
                    key={index}
                    className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                    aria-controls={`tabs-${index}-panel`}
                    role="tab"
                    type="button"
                    onClick={() => setMainImage(image)}
                  >
                    <span className="sr-only">{`Image ${index + 1}`}</span>
                    <span className="absolute inset-0 rounded-md overflow-hidden">
                      <img
                        src={image}
                        alt=""
                        className="w-full h-full object-center object-cover"
                      />
                    </span>
                  </button>
                ))}
              </div>
            </div>

            {/* Main image */}
            <div className="w-full aspect-w-1 aspect-h-1">
              <img
                src={mainImage}
                alt={product.name}
                className="w-full h-full object-center object-cover sm:rounded-lg"
              />
            </div>
          </div>

          {/* Right column - Product info */}
          <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
              {product.name}
            </h1>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl text-gray-900">
                Rs. {product.price.toFixed(2)}
              </p>
            </div>

            {/* Reviews */}
            <div className="mt-3">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <Star
                      key={rating}
                      className={`${
                        product.rating > rating
                          ? "text-yellow-400"
                          : "text-gray-300"
                      } h-5 w-5 flex-shrink-0`}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{product.rating} out of 5 stars</p>
                <a
                  href="#reviews"
                  className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {product.reviews} reviews
                </a>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="sr-only">Description</h3>
              <p className="text-base text-gray-900">{product.description}</p>
            </div>

            <form className="mt-6">
              {/* Colors */}
              {/* <div>
              <h3 className="text-sm text-gray-600 font-medium">Color</h3>
              <fieldset className="mt-2">
                <legend className="sr-only">Choose a color</legend>
                <div className="flex items-center space-x-3">
                  {product.colors.map((color) => (
                    <label
                      key={color}
                      className={`-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none ${
                        selectedColor === color ? "ring ring-offset-1" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="color-choice"
                        value={color}
                        className="sr-only"
                        aria-labelledby={`color-choice-${color}-label`}
                        onChange={() => setSelectedColor(color)}
                      />
                      <span
                        aria-hidden="true"
                        className={`h-8 w-8 bg-${color.toLowerCase()}-500 border border-black border-opacity-10 rounded-full`}
                      ></span>
                      <span
                        id={`color-choice-${color}-label`}
                        className="sr-only"
                      >
                        {color}
                      </span>
                    </label>
                  ))}
                </div>
              </fieldset>
            </div> */}

              {/* Sizes */}
              <div className="mt-8">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm text-gray-600 font-medium">Weight</h3>
                  <a
                    href="#"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {/* Size guide */}
                  </a>
                </div>
                <fieldset className="mt-2">
                  <legend className="sr-only">Choose a size</legend>
                  <div className="grid grid-cols-5 gap-3 sm:grid-cols-5">
                    {product.sizes.map((size) => (
                      <label
                        key={size}
                        className={`group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 ${
                          selectedSize === size
                            ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                            : "bg-white border-gray-300 text-gray-900 hover:bg-gray-50"
                        }`}
                      >
                        <input
                          type="radio"
                          name="size-choice"
                          value={size}
                          className="sr-only"
                          aria-labelledby={`size-choice-${size}-label`}
                          onChange={() => setSelectedSize(size)}
                        />
                        <span id={`size-choice-${size}-label`}>{size}</span>
                      </label>
                    ))}
                  </div>
                </fieldset>
              </div>

              {/* Quantity */}
              <div className="mt-8">
                <h3 className="text-sm text-gray-600 font-medium mb-2">
                  Quantity
                </h3>
                <input
                  type="number"
                  name="quantity"
                  min="1"
                  max="10"
                  value={quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  className="w-16 text-center border border-gray-300 rounded-md"
                />
              </div>

              {/* Add to cart button */}
              <div className="mt-10">
                <button
                  type="submit"
                  disabled={added}
                  onClick={(e) => handleAddToCart(e, product)}
                  className={`w-full ${added ? "bg-indigo-300 cursor-not-allowed" : "bg-indigo-600"} border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  {added ? "In the Cart" : "Add to Cart"}
                </button>
              </div>
            </form>

            {/* Additional product info */}
            <section className="mt-10">
              <h2 className="text-sm font-medium text-gray-900">Highlights</h2>
              <div className="mt-4 space-y-6">
                <ul className="pl-4 list-disc text-sm space-y-2">
                  {product.features.map((feature, index) => (
                    <li key={index} className="text-gray-400">
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            {/* Shipping info */}
            <section className="mt-8">
              <h2 className="text-sm font-medium text-gray-900">
                Shipping and Returns
              </h2>
              <div className="mt-4 space-y-6">
                <ul className="pl-4 list-disc text-sm space-y-2">
                  <li className="text-gray-400">
                    <Truck className="w-5 h-5 inline-block mr-2" />
                    Free shipping on orders over Rs. 250
                  </li>
                  <li className="text-gray-400">
                    <RotateCcw className="w-5 h-5 inline-block mr-2" />
                    30-day return policy
                  </li>
                  <li className="text-gray-400">
                    <ShieldCheck className="w-5 h-5 inline-block mr-2" />
                    Secure payment options
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
