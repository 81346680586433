import React, { useEffect, useState, useCallback, useRef } from "react";
// import template from "./cert.png";
import template2 from "./cert2.png";
import template3 from "./cert3.png";
import sign from "./63f625050c1df924c4aac61c_mj-min.png";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
// // import { Button } from "@/material";
import { db } from "../../../firebase";
import html2canvas from "html2canvas"; // Import html2canvas

function Cert() {
  const ref = useRef(null);
  const { id } = useParams();
  const [certData, setCertData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gender, setGender] = useState(true);

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${certData.name}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchDoc = doc(db, "temp", id);
        const snap = await getDoc(fetchDoc);

        if (snap.exists()) {
          setCertData(snap.data());
        } else {
          setError("No data found");
        }
      } catch (err) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);
  const handleDownload = async () => {
    try {
      const element = ref.current;

      // Ensure the image is loaded before capturing
      const images = element.querySelectorAll("img");
      await Promise.all(
        Array.from(images).map((img) => {
          return new Promise((resolve) => {
            if (img.complete) {
              resolve();
            } else {
              img.onload = resolve;
              img.onerror = resolve;
            }
          });
        })
      );

      const canvas = await html2canvas(element, {
        useCORS: true, // Enable CORS if possible
      });
      const imgData = canvas.toDataURL("image/jpeg");

      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${certData.name}_Acknowledgement_slip.jpg`;
      link.click();
    } catch (error) {
      console.error("Error capturing or downloading image: ", error);
    }
  };
  return (
    <div className="flex items-center justify-center overflow-x-auto flex-col min-h-screen">
      <div className="w-[700px] h-[490px] overflow-hidden relative" ref={ref}>
        <img src={template3} />
        <div className="absolute top-[280px] text-black font-serif italic text-lg left-[150px]">
          <h1>{certData.name}</h1>
        </div>
        <img
          src={sign}
          className="absolute bottom-[100px] right-[140px] w-36"
        />
      </div>
      <button
        className="bg-black text-white mt-2 py-1 px-4 rounded-md hover:bg-gray-800"
        onClick={handleDownload}
      >
        Click me
      </button>
    </div>
  );
}

export default Cert;
