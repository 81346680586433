import React from "react";
import { Button } from "flowbite-react";
import { AiFillGoogleCircle } from "react-icons/ai";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { app, db } from "../firebase";
import { useDispatch } from "react-redux";
import {
  signInSuccess,
  signInFailure,
  isAdmin,
} from "../redux/user/userSlice.js";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { UserDocument } from "../models/user.js"; // Import the UserDocument class

function OAuth() {
  const auth = getAuth(app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoogleClick = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });

    try {
      const resultsFromGoogle = await signInWithPopup(auth, provider);
      const user = resultsFromGoogle.user;

      const userDoc = new UserDocument(user);
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        await setDoc(userDocRef, { ...userDoc });
      }

      dispatch(
        signInSuccess({
          displayName: userDoc.displayName,
          email: userDoc.email,
          photoUrl: userDoc.photoUrl,
          uid: user.uid,
        })
      );
      if (userDocSnapshot.data().isAdmin === true) {
        dispatch(
          isAdmin({
            isAdmin: true,
          })
        );
        // navigate("/");
      } else {
        navigate("/");
      }
    } catch (error) {
      dispatch(signInFailure());
      console.log(error);
    }
  };

  return (
    <Button
      type="button"
      gradientDuoTone="pinkToOrange"
      outline
      onClick={handleGoogleClick}
    >
      <AiFillGoogleCircle className="w-6 h-6 mr-6" />
      Sign In with Google
    </Button>
  );
}

export default OAuth;
