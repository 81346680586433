import React from "react";
import MarketPlaceTopSection from "../../components/marketPlace/MarketPlaceTopSection";
import FirstProductLine from "../../components/marketPlace/FirstProductLine";
import ProductsPage from "../../components/marketPlace/ProductsPage";
import MarketplaceSubHeader from "../../components/marketPlace/MarketplaceSubHeader";

function MarketPlace() {
  return (
    <div>
      <MarketplaceSubHeader />
      <MarketPlaceTopSection />
      <FirstProductLine />
    </div>
  );
}

export default MarketPlace;
