import { auth, db } from "../firebase";
import { useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Button } from "flowbite-react";
import { UserDocument } from "../models/user";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  signInSuccess,
  signInFailure,
  isAdmin,
} from "../redux/user/userSlice.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "otp-input-react";
import { CircularProgressbar } from "react-circular-progressbar";

function PhoneAuth() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initializeRecaptcha = async () => {
    setLoading(true);
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = await new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // Handle reCAPTCHA response if needed
          },
          "expired-callback": () => {
            setError("reCAPTCHA expired. Please try again.");
          },
        }
      );
    }
  };
  const onSignInSubmit = async () => {
    await initializeRecaptcha(); // Initialize reCAPTCHA before making the sign-in request
    setLoading(true);
    const appVerifier = await window.recaptchaVerifier;
    await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(async (result) => {
        setConfirmationResult(result);
        setIsCodeSent(true);
      })
      .catch((error) => {
        console.error("Error sending SMS:", error);
        setError("Error sending SMS. Please try again.");
        setLoading(false);
      });
  };

  const onVerifyCodeSubmit = () => {
    setLoading1(true);
    if (confirmationResult) {
      confirmationResult
        .confirm(verificationCode)
        .then(async (result) => {
          const user = result.user;
          try {
            const userDoc = new UserDocument(user);
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (!userDocSnapshot.exists()) {
              await setDoc(userDocRef, { ...userDoc });
            }
            dispatch(
              signInSuccess({
                uid: user.uid,
              })
            );
            if (userDocSnapshot.data().isAdmin === true) {
              dispatch(
                isAdmin({
                  isAdmin: true,
                })
              );
              // navigate("/");
              setLoading1(false);
            } else {
              // navigate("/");
              setLoading1(false);
            }
          } catch (error) {
            console.error("Failed saving in firestore", error);
          }
        })
        .catch((error) => {
          console.error("Error verifying code:", error);
          setError("Invalid verification code. Please try again.");
          setLoading1(false);
        });
    }
  };
  return (
    <div className="flex justify-center flex-col items-center">
      <div id="recaptcha-container"></div>
      {!isCodeSent ? (
        <div className="flex flex-col gap-2">
          <PhoneInput
            country={"in"}
            value={phoneNumber}
            onChange={(phoneNumber) => setPhoneNumber("+" + phoneNumber)}
          />
          <Button
            type="submit"
            gradientDuoTone="purpleToBlue"
            onClick={onSignInSubmit}
            disabled={loading}
          >
            {loading ? (
              <div className="flex items-center">
                <CircularProgressbar className="w-5 h-5 mr-2 text-white" />
                Sending OTP...
              </div>
            ) : (
              "Send OTP"
            )}
          </Button>{" "}
        </div>
      ) : (
        <div className="flex flex-col items-center gap-2 justify-center">
          <OtpInput
            value={verificationCode}
            onChange={setVerificationCode}
            OTPLength={6}
            otpType="number"
            disabled={false}
            autoFocus
            className="ml-4"
          ></OtpInput>
          <Button
            type="submit"
            gradientDuoTone="purpleToBlue"
            onClick={onVerifyCodeSubmit}
            disabled={loading1}
          >
            {loading1 ? (
              <div className="flex items-center">
                <CircularProgressbar className="w-5 h-5 mr-2 text-white" />
                Verifying...
              </div>
            ) : (
              "Verify OTP"
            )}
          </Button>
        </div>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

export default PhoneAuth;
