export class UserDocument {
  constructor(user) {
    this.roleIds = [];
    this.email = user.email || null;
    this.displayName = user.displayName || null;
    this.photoUrl = user.photoURL || null;
    this.phoneNumber = user.phoneNumber || null;
    this.googleCloudMessagingToken = null;
    this.bloodGroup = null;
    this.isOptedInForDonation = false;
    this.timestamp = new Date().toISOString();
  }
}