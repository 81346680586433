import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import SignInModal from "../SignInModal";

function PrivateRoute() {
  const [openModal, setOpenModal] = useState(true);

  const { currentUser } = useSelector((state) => state.user);

  return currentUser ? (
    <Outlet />
  ) : (
    <SignInModal openModal={openModal} setOpenModal={setOpenModal} />
  );
}

export default PrivateRoute;
