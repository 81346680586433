import React from "react";
import { useSelector } from "react-redux";

function ThemeProvider({ children }) {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div className={theme}>
      <div className=" text-black dark:text-white bg-gradient-to-br from-white to-gray-200 dark:from-yellow-100 dark:to-black min-h-screen">
        {children}
      </div>
    </div>
  );
}

export default ThemeProvider;
