import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Star } from "lucide-react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import turmeric from "../../assets/marketPlace/turmeric/NEOriginsLakadongTurmericPR00-1.webp";
import tea from "../../assets/marketPlace/tea/new-temi-tea-first-flush.webp";
// Sample product data
const products = [
  {
    id: 1,
    name: "Lakadong Turmeric, 50g",
    price: 230,
    // rating: 4.5,
    image: turmeric,
  },
  {
    id: 2,
    name: "Temi Tea First Flush, 100g",
    price: 500,
    // rating: 4.2,
    image: tea,
  },
  {
    id: 3,
    name: "Lakadong Turmeric, 50g",
    price: 230,
    // rating: 4.5,
    image: turmeric,
  },
  {
    id: 4,
    name: "Temi Tea First Flush, 100g",
    price: 500,
    // rating: 4.2,
    image: tea,
  },
  {
    id: 5,
    name: "Lakadong Turmeric, 50g",
    price: 230,
    // rating: 4.5,
    image: turmeric,
  },
  {
    id: 6,
    name: "Temi Tea First Flush, 100g",
    price: 500,
    // rating: 4.2,
    image: tea,
  },
];
export default function FirstProductLine() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleProducts, setVisibleProducts] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setVisibleProducts(1);
      } else if (window.innerWidth < 1024) {
        setVisibleProducts(2);
      } else {
        setVisibleProducts(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleProducts >= products.length ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? products.length - visibleProducts : prevIndex - 1
    );
  };

  return (
    <div className="w-full max-w-6xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6 text-center">Featured Products</h2>
      <div className="relative">
        <div className="overflow-hidden">
          <div
            className="flex transition-transform duration-300 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * (100 / visibleProducts)}%)`,
            }}
          >
            {products.map((product) => (
              <div
                key={product.id}
                className={`flex-shrink-0 w-full sm:w-1/2 lg:w-1/3 p-2`}
                style={{ flex: `0 0 ${100 / visibleProducts}%` }}
              >
                <Link to={`/market/${product.id}`}>
                  <div className="bg-white rounded-lg shadow-md overflow-hidden">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-4">
                      <h3 className="font-semibold text-lg mb-2">
                        {product.name}
                      </h3>
                      {/* <div className="flex items-center mb-2">
                        {[...Array(5)].map((_, i) => (
                          <Star
                            key={i}
                            className={`w-4 h-4 ${
                              i < Math.floor(product.rating)
                                ? "text-yellow-400 fill-current"
                                : "text-gray-300"
                            }`}
                          />
                        ))}
                        <span className="ml-2 text-sm text-gray-600">
                          ({product.rating})
                        </span>
                      </div> */}
                      <p className="text-xl font-bold mb-4">
                        Rs. {product.price.toFixed(2)}
                      </p>
                      <button className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors">
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={prevSlide}
          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          aria-label="Previous product"
        >
          <ChevronLeft className="w-6 h-6 text-gray-800" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          aria-label="Next product"
        >
          <ChevronRight className="w-6 h-6 text-gray-800" />
        </button>
      </div>
    </div>
  );
}
